import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import Background from './assets/images/background.jpg';
import Root from './pages/Root';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <div
        className="BackgroundContainer"
        style={{
          backgroundImage: `url('${Background}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
        <Root />
      </div>
    </div>
  );
}

export default App;
