import React from 'react';
import Web3Modal from 'web3modal';
import Web3 from 'web3';
import warriorsAbi from '../galaxy-warriors.json';
import airdropAbi from '../galaxy-airdrop.json';
import { AbiItem } from 'web3-utils';
import { Contract } from 'web3-eth-contract';
import WalletConnectProvider from '@walletconnect/web3-provider';
import config from '../config';
import MetamaskIcon from '../assets/images/metamask.svg';
import CoinbaseIcon from '../assets/images/coinbase.svg';
import { isMobile } from 'react-device-detect';
import WalletLink from 'walletlink';

interface GWContextState {
  presalePrice?: number;
  price?: number;
  totalSupply?: number;
  totalNumberOfGalaxyWarriors?: number;
  totalGiveawayReserved?: number;
  isMintActive?: boolean;
  isPreMintActive?: boolean;
  balance?: number;
  airdropBalance?: number;

  connect?: () => void;
  disconnect?: () => void;
  isConnected?: boolean;
  getAll?: () => void;
  mint?: (count: number, done: (success: boolean) => void) => void;
  preMint?: (count: number, done: (success: boolean) => void) => void;
  getTotalGiveawayReserved?: () => void;
}

const defaultValue: GWContextState = {};

export const GWContext = React.createContext<GWContextState>(defaultValue);

export const GWProvider: React.FC = ({ children }) => {
  const [web3, setWeb3] = React.useState<Web3>();
  const [warriorsContract, setWarriorContract] = React.useState<Contract>();
  const [airdropContract, setAirdropContract] = React.useState<Contract>();
  const [isConnected, setIsConnected] = React.useState(false);
  const [presalePrice, setPresalePrice] = React.useState<number>();
  const [price, setPrice] = React.useState<number>();
  const [totalSupply, setTotalSupply] = React.useState<number>();
  const [totalNumberOfGalaxyWarriors, setTotalNumberOfGalaxyWarriors] =
    React.useState<number>();
  const [preMintReserved, setPreMintReserved] = React.useState<number>();
  const [totalGiveawayReserved, setTotalGiveawayReserved] =
    React.useState<number>();
  const [isMintActive, setIsMintActive] = React.useState(false);
  const [isPreMintActive, setIsPreMintActive] = React.useState(false);
  const [balance, setBalance] = React.useState<number>();
  const [airdropBalance, setAirdropBalance] = React.useState<number>();

  React.useEffect(() => {
    const checkConnection = async () => {
      // Check if browser is running Metamask
      let newWeb3: any;
      if (window.ethereum) {
        newWeb3 = new Web3(window.ethereum);
      } else if (window.web3) {
        newWeb3 = new Web3(window.web3.currentProvider);
      }

      newWeb3?.eth?.getAccounts().then(async (addr: string[]) => {
        if (addr.length > 0) {
          setWeb3(newWeb3);
        }
      });
    };
    checkConnection();
  }, []);

  React.useEffect(() => {
    if (web3) {
      createContracts();
    }
  }, [web3]);

  React.useEffect(() => {
    if (warriorsContract && airdropContract) {
      setIsConnected(true);
      getAll();
    }
  }, [warriorsContract, airdropContract]);

  const connect = async () => {
    const web3Modal = new Web3Modal({
      network: config.network,
      cacheProvider: false,
      disableInjectedProvider: true,
      providerOptions,
    });

    const provider = await web3Modal.connect();
    if (!provider) {
      throw Error(
        'Provider is null, please make sure your wallet is connected',
      );
    }
    const web3 = new Web3(provider);
    setWeb3(web3);
  };

  const createContracts = async () => {
    if (web3) {
      const wContract = new web3.eth.Contract(
        warriorsAbi as unknown as AbiItem,
        config.warriorsContract,
      );

      const aContract = new web3.eth.Contract(
        airdropAbi as unknown as AbiItem,
        config.airdropContract,
      );

      setWarriorContract(wContract);
      setAirdropContract(aContract);
    }
  };

  const disconnect = async () => {
    setBalance(undefined);
    setTotalGiveawayReserved(undefined);
    setPreMintReserved(undefined);
    setIsMintActive(false);
    setIsPreMintActive(false);
  };

  const getIsMintActive = async () => {
    warriorsContract?.methods
      .isMintActive()
      .call()
      .then((result: boolean) => {
        setIsMintActive(result);
      });
  };

  const getIsPreMintActive = async () => {
    warriorsContract?.methods
      .isPreMintActive()
      .call()
      .then((result: boolean) => {
        setIsPreMintActive(result);
      });
  };

  const getTotalGiveawayReserved = async () => {
    warriorsContract?.methods
      .totalGiveawayReserved()
      .call()
      .then((result: number) => {
        setTotalGiveawayReserved(result);
      });
  };

  const getBalance = async () => {
    const account = (await web3?.eth.getAccounts())?.[0];

    warriorsContract?.methods
      .balanceOf(account)
      .call()
      .then((result: any) => {
        setBalance(result);
      });
  };

  const getAirdropBalance = async () => {
    const account = (await web3?.eth.getAccounts())?.[0];

    airdropContract?.methods
      .balanceOf(account, 0)
      .call()
      .then((result: any) => {
        setAirdropBalance(result);
      });
  };

  const getSupply = async () => {
    warriorsContract?.methods
      .totalSupply()
      .call()
      .then((result: any) => {
        setTotalSupply(result);
      });

    warriorsContract?.methods
      .TOTAL_NUMBER_OF_GALAXY_WARRIORS()
      .call()
      .then((result: any) => {
        setTotalNumberOfGalaxyWarriors(result);
      });
  };

  const getPrices = async () => {
    warriorsContract?.methods
      .PRESALE_PRICE()
      .call()
      .then((result: any) => {
        setPresalePrice(result);
      });

    warriorsContract?.methods
      .PRICE()
      .call()
      .then((result: any) => {
        setPrice(result);
      });
  };

  const waitForTransaction = (hash: string, callback: () => void) => {
    const interval = setInterval(function () {
      console.log('Attempting to get transaction receipt...');
      web3?.eth?.getTransactionReceipt(hash, function (err, rec) {
        if (rec) {
          console.log(rec);
          callback();
          clearInterval(interval);
        }
      });
    }, 1000);
  };

  const mint = async (count: number, done: (success: boolean) => void) => {
    const account = (await web3?.eth.getAccounts())?.[0];

    warriorsContract?.methods
      .mint(count)
      .send({ from: `${account}`, value: count * price! })
      .on('transactionHash', function (hash: string) {
        console.log(`Error::: ${hash}`);
        waitForTransaction(hash, () => {
          getAll();
          done(true);
        });
      })
      .on('error', function (error: any) {
        done(false);
      });
  };

  const preMint = async (count: number, done: (success: boolean) => void) => {
    const account = (await web3?.eth.getAccounts())?.[0];

    warriorsContract?.methods
      .preMint(count)
      .send({ from: `${account}`, value: count * presalePrice! })
      .on('transactionHash', function (hash: string) {
        waitForTransaction(hash, () => {
          getAll();
          done(true);
        });
      })
      .on('error', function (error: any) {
        done(false);
      });
  };

  const getAll = () => {
    getIsMintActive();
    getIsPreMintActive();
    getTotalGiveawayReserved();
    getBalance();
    getAirdropBalance();
    getPrices();
    getSupply();
  };

  return (
    <GWContext.Provider
      value={{
        isConnected,
        presalePrice,
        price,
        totalSupply,
        totalNumberOfGalaxyWarriors,
        totalGiveawayReserved,
        isMintActive,
        isPreMintActive,
        balance,
        airdropBalance,
        connect,
        disconnect,
        getAll,
        mint,
        preMint,
        getTotalGiveawayReserved,
      }}>
      {children}
    </GWContext.Provider>
  );
};

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: config.infuraId,
    },
  },
  'custom-metamask': {
    display: {
      logo: MetamaskIcon,
      name: 'MetaMask',
      description: 'Connect to your MetaMask Wallet',
    },
    package: true,
    connector: async () => {
      let provider = null;
      if (window?.ethereum) {
        const providers = window.ethereum.providers;
        // if there are no multiple injected providers available, return the injected provider
        provider = providers
          ? providers.find((p: any) => p.isMetaMask)
          : window.ethereum; // <-- LOOK HERE
        try {
          await provider.request({ method: 'eth_requestAccounts' });
        } catch (error) {
          throw new Error('User Rejected');
        }
      } else {
        if (isMobile) {
          window.open(config.metamaskDeeplink, '_blank')?.focus();
          return;
        }
      }
      console.log('MetaMask provider', provider);
      return provider;
    },
  },
  'custom-coinbase': {
    display: {
      logo: CoinbaseIcon,
      name: 'Coinbase',
      description: 'Scan with WalletLink to connect',
    },
    options: {
      appName: 'Galaxy Warriors', // Your app name
      infuraId: config.infuraId,
      networkUrl: `https://mainnet.infura.io/v3/${config.infuraId}`,
      chainId: 1,
    },
    package: WalletLink,
    connector: async (_: any, options: any) => {
      const { appName, networkUrl, chainId } = options;
      const walletLink = new WalletLink({
        appName,
      });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      await provider.send('eth_requestAccounts');
      return provider;
    },
  },
};
