import { GWContext } from '../contexts/GWContext';
import React from 'react';
import ActionButton from '../Components/ActionButton';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const Connect: React.FC = () => {
  const ctx = React.useContext(GWContext);

  return (
    <Wrapper>
      <h1 style={{ textAlign: 'start', fontFamily: 'Merriweather Sans' }}>
        Galaxy Warriors
      </h1>
      <ActionButton
        onClick={async () => {
          try {
            await ctx.connect?.();
          } catch (e) {
            console.log(e);
            toast.error('Connect failed. Please install or connect MetaMask');
          }
        }}>
        Connect Wallet
      </ActionButton>
    </Wrapper>
  );
};

export default Connect;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
