import styled from 'styled-components';
import { Button } from 'reakit/Button';
import { FiMinus, FiPlus } from 'react-icons/fi';
import React from 'react';

interface Props {
  count: number;
  lowerLimit: number;
  upperLimit: number;
  onChanged?: (value: number) => void;
}

const CountPicker: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <CounterButton
        onClick={() => {
          if (props.count - 1 >= props.lowerLimit) {
            props.onChanged?.(props.count - 1);
          }
        }}>
        <FiMinus />
      </CounterButton>
      <PurchaseCount>{props.count}</PurchaseCount>
      <CounterButton
        onClick={() => {
          if (props.count + 1 <= props.upperLimit)
            props.onChanged?.(props.count + 1);
        }}>
        <FiPlus />
      </CounterButton>
    </Wrapper>
  );
};

export default CountPicker;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  border-radius: 8px;
  margin-top: 24px;
  width: 100%;
`;

const CounterButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 solid;
  height: 100%;
  flex: 1;
  font-size: 42px;
  color: white;
  padding: 8px 0;
  background-color: transparent;
`;

const PurchaseCount = styled.div`
  border: 0px solid #ffffff;
  border-radius: 0px;
  padding: 0 20px;

  color: ${(props) => props.color ?? '#ffffff'};
  font-size: 42px;
  font-family: 'Bebas Neue';
`;
