import React from 'react';
import styled from 'styled-components';

const PreLaunch: React.FC = () => {
  return <Text>Sale is closed at the moment</Text>;
};

export default PreLaunch;

const Text = styled.div`
  font-size: 20px;
  font-family: 'IBM Plex Sans';
  color: #ffffff;
  font-weight: 700;
`;
