import styled from 'styled-components';

interface Props {
  show?: boolean;
  title?: string | number;
  titleColor?: string;
  text?: string;
}

const GridTile: React.FC<Props> = (props) => {
  return (
    <Wrapper show={props.show}>
      <TextTitle color={props.titleColor}>{props.title ?? ''}</TextTitle>
      <TextSubtitle>{props.text ?? ''}</TextSubtitle>
    </Wrapper>
  );
};

export default GridTile;

const Wrapper = styled.div<{ show?: boolean }>`
  height: ${(props) => (props.show ? 'unset' : '0px')};
  display: ${(props) => (props.show ? 'flex' : 'none')};
  background-color: rgb(34, 39, 56);
  border-radius: 4px;
  margin: 4px;
  padding: 12px 12px;
  flex: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  transition: 0.1s ease-out;
`;

const TextTitle = styled.div<{ color?: string }>`
  color: ${(props) => props.color ?? '#ffffff'};
  font-size: 52px;
  font-family: 'Bebas Neue';
`;

const TextSubtitle = styled.div`
  font-size: 12px;
  font-family: 'IBM Plex Sans';
  color: #bbbbbb;
`;
