import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'reakit/Checkbox';

interface Props {
  style?: CSSProperties;
  onChecked?: (checked: boolean) => void;
}

const AgreeToTerms: React.FC<Props> = (props) => {
  const [checked, setChecked] = React.useState(false);
  const toggle = () => {
    setChecked(!checked);
    props.onChecked?.(!checked);
  };

  const isTextOnly = !props.onChecked;

  return (
    <Wrapper style={props.style}>
      {isTextOnly ? (
        <Text>
          {'By purchasing, I fully agree to the '}
          <TextLink
            target="_blank"
            rel="noopener noreferrer"
            href={
              'https://docs.google.com/document/d/e/2PACX-1vQAnPCPNmMU6cogArj9yw1FHzQTzwV6nTGR9s-ZRYTxfspKm517SfACFZ8yAnX1TlT4nK-TQN4G8m-E/pub'
            }>
            Terms & Conditions
          </TextLink>
        </Text>
      ) : (
        <>
          <Checkbox checked={checked} onChange={toggle} />
          <Text>I agree to the Terms and Conditions</Text>
        </>
      )}
    </Wrapper>
  );
};

export default AgreeToTerms;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  background-color: rgba(42, 42, 42, 0.84);
  border-radius: 4px;
  padding: 4px 8px;
`;

const Text = styled.div`
  font-size: 14px;
  font-family: 'IBM Plex Sans';
  color: #ffffff;
  font-weight: 700;
`;

const TextLink = styled.a`
  color: #ffffff;
  font-size: 14px;
  font-family: 'IBM Plex Sans';
`;
