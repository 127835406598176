import React from 'react';
import styled from 'styled-components';
import { GWContext } from '../contexts/GWContext';
import Web3 from 'web3';
import ActionButton from '../Components/ActionButton';
import AgreeToTerms from '../Components/AgreeToTerms';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import GridTile from '../Components/GridTile';
import CountPicker from '../Components/CountPicker';

const PreSale: React.FC = () => {
  const ctx = React.useContext(GWContext);
  const [purchaseCount, setPurchaseCount] = React.useState(1);

  return (
    <Wrapper>
      <h1 style={{ textAlign: 'start', fontFamily: 'Merriweather Sans' }}>
        Galaxy Warriors
      </h1>
      <h2 style={{ textAlign: 'start', fontFamily: 'Merriweather Sans' }}>
        Presale
      </h2>
      <Row>
        <GridTile
          show={ctx.isConnected}
          title={
            (ctx.totalNumberOfGalaxyWarriors ?? 0) -
            (ctx.totalSupply ?? 0) -
            (ctx.totalGiveawayReserved ?? 0)
          }
          text={'Warriors Available'}
        />
        <GridTile
          show={ctx.isConnected}
          title={`${Web3.utils.fromWei(`${ctx.presalePrice ?? 0}`)}`}
          text={'Presale Price (ETH)'}
        />
      </Row>
      <Row>
        <GridTile
          show={ctx.isConnected}
          title={ctx.airdropBalance}
          titleColor={(ctx.airdropBalance ?? 0) > 0 ? '#36fc61' : '#ff4040'}
          text={'Tokens Left'}
        />
      </Row>

      <>
        <CountPicker
          count={purchaseCount}
          lowerLimit={1}
          upperLimit={ctx.airdropBalance ?? 1}
          onChanged={(value) => setPurchaseCount(value)}
        />
        <AgreeToTerms />
        <PurchaseBtn
          onClick={() => {
            if (ctx.airdropBalance && ctx.airdropBalance <= 0) {
              toast(
                "You don't have any tokens left. If you think this is a mistake, please refresh the page.",
              );
              return;
            }

            if (purchaseCount > 0) {
              const toastId = toast.loading('Transaction Pending...', {
                autoClose: false,
              });
              ctx.preMint?.(purchaseCount, (success) => {
                toast.dismiss(toastId);
                if (success) {
                  toast.success('Transaction Completed');
                } else {
                  toast.error('Transaction Failed');
                }
              });
              return;
            }
          }}>
          Purchase Galaxy Warriors
        </PurchaseBtn>
      </>
    </Wrapper>
  );
};

export default PreSale;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const ConnectBtn = styled(ActionButton)`
  margin-top: 12px;
`;

const PurchaseBtn = styled(ActionButton)`
  margin-top: 12px;
`;
