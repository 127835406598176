const URL = 'galaxy-warriors.vercel.app';

const config = {
  infuraId:
    process.env.REACT_APP_INFURA_ID ?? '4c84cc7f724e48b293949ad57ab1c193',
  metamaskDeeplink: `https://metamask.app.link/dapp/${
    process.env.REACT_APP_URL ?? URL
  }/`,
  warriorsContract:
    process.env.REACT_APP_WARRIORS_CONTRACT ??
    '0x74A06301d891d8848aaCD72c7024e583D87078D8',
  airdropContract:
    process.env.REACT_APP_AIRDROP_CONTRACT ??
    '0x986F1dD0c332a169ECEb544a0e178DF2709c0922',
  network: process.env.REACT_APP_NETWORK ?? 'rinkeby',
};

export default config;
