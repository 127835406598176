import styled from 'styled-components';
import { Button } from 'reakit/Button';

const ActionButton = styled(Button)`
  width: 100%;
  background-color: #4408d2;
  box-shadow: 1px 1px 8px 4px #0c0328;
  border: 0px solid #150242;
  color: #ffffff;
  font-weight: 700;
  font-size: 24px;
  font-family: 'Merriweather Sans';
  border-radius: 8px;
  padding: 12px 24px;
  transition: 0.1s ease-out;

  &:hover {
    background-color: #5c24f1;
    color: #ffffff;
  }
`;

export default ActionButton;
