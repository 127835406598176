import { GWContext } from '../contexts/GWContext';
import React from 'react';
import PreLaunch from './PreLaunch';
import Presale from './Presale';
import Sale from './Sale';
import Connect from './Connect';

const Root: React.FC = () => {
  const ctx = React.useContext(GWContext);

  return ctx.isMintActive ? (
    <Sale />
  ) : ctx.isPreMintActive ? (
    <Presale />
  ) : ctx.isConnected ? (
    <PreLaunch />
  ) : (
    <Connect />
  );
};

export default Root;
